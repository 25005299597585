import user_test from '../components/data/user.json';

export default class StatistikService {
    _apiBase = 'https://backend.saljtavla.svenskamaklarhuset.se/api/v1';
    // _apiBase = 'http://127.0.0.1:8000/api/v1';


    async userAuth(url, user) {
        const res = await fetch(`${this._apiBase}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({...user})
        });

        if (!res.ok) {
            const status = res.status;
            if (status === 422) {
                throw await res.json();
            }
            throw res;
        }
        return await res.json()
    }

    getCurrentSvedenMonth() {
        let sv_months = new Map([
            [1, 'januari'],
            [2, 'februari'],
            [3, 'mars'],
            [4, 'april'],
            [5, 'maj'],
            [6, 'juni'],
            [7, 'juli'],
            [8, 'augusti'],
            [9, 'september'],
            [10, 'oktober'],
            [11, 'november'],
            [12, 'december'],
        ])
        return sv_months.get(new Date().getMonth()
            + 1
        );
    }

    async removeImage(image_id) {
        await fetch(`${this._apiBase}/admin/carousel-images/${image_id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
        }).then(res => res.json())
            .then(
                (result) => {
                    return result;
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    async getUser(token) {
        await fetch(`${this._apiBase}/auth/user`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }).then(res => {
            if (res.status === 401) {
                throw new Error('Unauthenticated');
            } else {
                res.json()
            }
        })
            .then(
                (result) => {
                    return result;
                },
                (error) => {
                    throw new Error(error);
                }
            )
    }

    async userRegister(user) {
        return await this.userAuth('/register', user);
    }

    async userLogin(user) {
        return await this.userAuth('/auth', user);
    }

    async userLogout(user) {
        await this.storageCleanup();
        return {revoked: true};
    }

    async userUpdateMessage(formdata) {
        await fetch(`${this._apiBase}/admin/settings`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
            body: formdata,
        }).then(res => res.json())
            .then(
                (result) => {
                    return result;
                },
                (error) => {
                    console.log(error);
                }
            );
    }


    async userUploadImages(formdata) {
        const token = '2|nIv8XYywMJoElNGewa2YXcwPb4gvF8kZ90l5wfte';

        await fetch(`${this._apiBase}/user/message/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
                // 'X-CSRF-TOKEN': token
            },
            body: JSON.stringify({formdata})
        }).then(res => res.json())
            .then(
                (result) => {
                    return result;
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    async userGetMessage() {
        await fetch(`${this._apiBase}/admin/settings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return result.message;
                },
                (error) => {
                    console.log(error);
                    return false;
                }
            );

    }

    async userUpdatePass(newpass, token) {
        const res = await fetch(`${this._apiBase}/profile/update-password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({password: newpass})
        });

        if (!res.ok) {
            throw new Error(`Could not fetch user, received ${res.status}`);
        }
        return await res.json();
    }

    async userForgotPass(email) {
        const res = await fetch(`${this._apiBase}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({email})
        });

        if (!res.ok) {
            throw new Error(`Could not fetch user, received ${res.status}`);
        }
        return await res.json();
    }

    async getTickerText(user) {
        let message = '';
        await fetch(`${this._apiBase}/data/settings`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
        }).then(res => res.json())
            .then(
                (result) => {
                    message = result.message;
                    localStorage.setItem('message', message);
                    localStorage.setItem('carouselImages', JSON.stringify(result.carouselImages))
                },
                (error) => {
                    console.log(error);
                }
            );
        return message;
    }

    async getTable() {
        const result = await fetch(`${this._apiBase}/data/reports`, {
            method: 'GET',
            headers: {
                "Content-Type" : "application/json",
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            }
        });

        return await result.json();
    }

    getTableItem (table) {
        return JSON.parse(localStorage.getItem(table));
    }

    async getlatestid() {
        if (localStorage.getItem('latestId')) {
            await fetch(`${this._apiBase}/getlatestid`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            }).then(res => res.json())
                .then(
                    (result) => {
                        if (result && result != localStorage.getItem('latestId')) {
                            this.minorStorageCleanup();

                            // localStorage.setItem('latestId', result)
                            setTimeout(()=>{
                                window.location.reload();
                            },10000);
                        }

                    },
                    (error) => {
                        console.log(error);
                    }
                ).catch((error) => {
                    setTimeout(()=>{window.location.reload()}, 60000);
                });
        }

    }

    async updateAccount(data) {
        // TEST
        return true;
        // TEST

        // const res = await fetch(`${this._apiBase}/auth`, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Accept': 'application/json'
        //   },
        //   body: data
        // });

        // if (!res.ok) {
        //   throw new Error(`Could not update data, received ${res.status}`);
        // }
        // return await res.json();
    }

    async storageCleanup() {
        localStorage.removeItem("latestId");
        localStorage.removeItem("board");
        localStorage.removeItem("leader_board");
        localStorage.removeItem("office_board");
        localStorage.removeItem("office_leader_board");
        localStorage.removeItem("single_office_data");
        localStorage.removeItem("multiple_single_office_data");
        localStorage.removeItem("auth-token");
        localStorage.removeItem("office_id");
    }
    async minorStorageCleanup() {
        localStorage.removeItem("latestId");
        localStorage.removeItem("board");
        localStorage.removeItem("leader_board");
        localStorage.removeItem("office_board");
        localStorage.removeItem("office_leader_board");
        localStorage.removeItem("single_office_data");
        localStorage.removeItem("multiple_single_office_data");
    }
}
